import { Box, Divider, IconButton, Tooltip, Typography, Table, TableBody, TableCell, TableRow, TableContainer, Paper } from "@mui/material";
import React, { useEffect } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { connect } from "react-redux";
import { showMessage } from "../../Utils/helper";
import { Button, CompactCustomTextField, Spinner } from "../../Components/UI";
import { FetchStats } from "../../Store/Actions/userActionCreator";

const quickFilters = "images/quickFilters.webp";

function ActivityDashboard({ dispatchFetchStats, ...props }) {
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState(null);

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = () => {
    setLoading(true);
    dispatchFetchStats(
      {},
      (resp) => {
        if (resp?.status) {
          setResponse(resp?.result);
        } else {
          showMessage(resp?.result, "error");
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        showMessage("Failed to get response from server", "error");
      }
    );
  };

  const isMobileView = window.innerWidth < 600;

  const formatNumber = (num) => {
    return num?.toLocaleString();
  };

  const renderTable = () => {
    if (!response) return null;

    const rows = [
      { label: "New Profiles in 30 Days", value: formatNumber(response.newUsersCount) },
      { label: "Active Users in 30 Days", value: formatNumber(response.activeUsersCount) },
      { label: "Marriages Settled in 30 Days", value: formatNumber(response.deletedUserCount) },
      // { label: "Invitations Sent", value: formatNumber(response.invitationsCount) },
      { label: "Invitations Accepted in 30 Days", value: formatNumber(response.acceptedInvitationsCount) },
      { label: "Total Marriages settled (from Nov 2022)", value: formatNumber(response.totalDeletedUserCount) },
      { label: "Total Active Profiles", value: formatNumber(response.totalUsersCount) },
    ];

    return (
      <TableContainer component={Paper} sx={{ mt: 4, borderRadius: 2, boxShadow: 3, border: "1px solid rgba(102, 51, 153, 0.5)" }}>
        <Table>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'rgba(102, 51, 153, 0.1)' } }}>
                <TableCell sx={{ fontWeight: "bold", color: "#673ab7", fontSize: "1rem", padding: "12px", border: "1px solid rgba(102, 51, 153, 0.2)" }}>{row.label}</TableCell>
                <TableCell sx={{ fontSize: "0.95rem", padding: "12px", border: "1px solid rgba(102, 51, 153, 0.2)" }}>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box sx={{ padding: isMobileView ? "16px 20px" : "60px 200px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Tooltip title="GO BACK">
          <IconButton
            aria-label="delete"
            sx={{ mr: "16px" }}
            onClick={(e) => {
              props?.history?.goBack();
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>

        <Typography
          variant={isMobileView ? "h6" : "h5"}
          color={"#000000"}
          fontWeight="bold"
          textAlign={"left"}
          mt={isMobileView ? "1em" : ""}
        >
          Application Usage Summary
        </Typography>
      </Box>
      <Divider
        sx={{
          borderColor: "rgba(102, 51, 153, 0.3)",
          my: "16px",
          mb: "30px",
        }}
      />

      <Spinner loading={loading}>
        {!loading && response && (
          <>
            {renderTable()}
            <Typography
              variant="body1"
              color="#673ab7"
              fontWeight="bold"
              sx={{ mt: 4, padding: "12px", border: "1px solid rgba(102, 51, 153, 0.5)", borderRadius: 1, backgroundColor: 'rgba(102, 51, 153, 0.1)' }}
            >
              User Queries: {response.queriesAddress}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ display: "block", mt: 2, fontStyle: "italic" }}
            >
              * The data displayed is live and reflects the most recent updates.
            </Typography>
          </>
        )}
      </Spinner>
    </Box>
  );
}

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchStats: (type, onSuccess, onError) =>
    dispatch(FetchStats(type, onSuccess, onError)),
});

export default connect(null, mapDispatchToProps)(ActivityDashboard);
