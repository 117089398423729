import React from "react";
import { Link } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import "./styles.scss";
import { PlaystoreBadge, AppstoreBadge } from "../../Components/UI";
function Copyright() {
  const currentYear = new Date().getFullYear();
  const isMobile = window.innerWidth < 600;
  return (
    <React.Fragment>
      <footer className="footer">
        <div style={{ display: isMobile ? "block" : "flex", justifyContent: "space-evenly", alignItems: "center", padding: "16px" }} >
          <Grid className="photogrid height30vh" item xs={4}>
            <img className="responsiveImg" src={"/images/logo.webp"} />
          </Grid>


          <Grid xs={12} >

            <Grid item xs={12} columnSpacing={{ xs: 1, sm: 2 }}  >
              <Typography fontSize={'1em'} variant="h6" color="textPrimary" gutterBottom>
                Contact Us:
              </Typography>
              <Typography
                fontSize={"1em"}
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                Jaya Satya Pramoda Nidhi,
                <br /> C/o. Uttaradi Math, Jayateertha Vidyapeetha,
                <br /> Uttaradi Math road, <br /> Basavanagudi, Bangalore - 560
                004 <br />
              </Typography>
              <Typography fontSize={'1em'} variant="h6" color="textPrimary">
                Email: <a style={{ color: "white" }} href="mailto:jspn.vvs@umapps.in">jspn.vvs@umapps.in</a>
              </Typography>
              <Typography fontSize={'1em'} variant="h6" color="textPrimary">
                WhatsApp: <a style={{ color: "white" }} href="https://wa.me/message/BN2R4ZFIMY4JM1">+91 63639 21008</a>
              </Typography>
            </Grid>


          </Grid>

          <Grid item xs={12} columnSpacing={{ xs: 1, sm: 2, }} >
            <ul>
              <li>
                <Typography fontSize={'1em'} variant="h6" color="#FFF" gutterBottom to="/terms-and-conditions" component={Link}>
                  Terms and Conditions
                </Typography>
              </li>
              <li>
                <Typography fontSize={'1em'} variant="h6" color="#FFF" gutterBottom to="/faq" component={Link}>
                  FAQs (Frequently Asked Questions)
                </Typography>
              </li>
              <li>
                <Typography fontSize={'1em'} variant="h6" color="#FFF" gutterBottom to="/privacy-policy" component={Link}>
                  Privacy Policy
                </Typography>
              </li>
              <li>
                <Typography fontSize={'1em'} variant="h6" color="#FFF" gutterBottom to="/cancellation-policy" component={Link}>
                  Cancellation/Refund Policy
                </Typography>
              </li>
              <li>
                <Typography fontSize={'1em'} variant="h6" color="#FFF" gutterBottom to="/activity-dashboard" component={Link}>
                  Activity Dashboard
                </Typography>
              </li>
            </ul>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%", padding: "8px" }}>
              <PlaystoreBadge />
              <AppstoreBadge />
            </div>
          </Grid>
        </div>
        <Typography paddingBottom={"96px"} textAlign={"center"} fontSize={'1em'} variant="h6" color="#FFF" gutterBottom>
          <br /> © 2013-{currentYear}  JAYA SATYA PRAMODA NIDHI. All rights reserved. <br />
        </Typography>
      </footer>
    </React.Fragment>
  );
}
export default function Footer(props) {
  return <div>{Copyright()}</div>;
}
