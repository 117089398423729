import { RouteLayouts } from "../../Utils/constants";
import Home from "../../Pages/Home";
import MyMatches from "../../Pages/MyMatches";
import Conversations from "../../Pages/Conversations";
import DetailProfile from "../../Pages/DetailProfile";
import MyInbox from "../../Pages/MyInbox";
import Register from "../../Pages/Registration";
import AdvanceForm from "../../Pages/Registration/AdvanceForm";
import BasicForm from "../../Pages/Registration/BasicForm";
import ProfileForm from "../../Pages/Registration/ProfileForm";
import UserProfile from "../../Pages/UserProfile";
import ProfileEdit from "../../Pages/UserProfile/ProfileEdit";
import ProfileDeletion from "../../Pages/UserProfile/ProfileDeletion";
import MyAccount from "../../Pages/UserProfile/MyAccount";
import Donate from "../../Pages/Donate";
import TermsAndConditions from "../../Pages/tnc";
import FAQ from "../../Pages/faq";
import ActivityDashboard from "../../Pages/ActivityDashboard";
import PrivacyPolicy from "../../Pages/privacyPolicy";
import CancellationPolicy from "../../Pages/cancellationPolicy";
import VVSPrime from "../../Pages/vvsPrime";
import Notification from "../../Pages/Notification";

const routes = [
  {
    path: "/register/basic",
    component: BasicForm,
    name: "Register",
    layout: RouteLayouts.RegistrationLayout,
  },
  {
    path: "/register/advance",
    component: AdvanceForm,
    name: "Register",
    layout: RouteLayouts.RegistrationLayout,
  },
  {
    path: "/register/profile",
    component: ProfileForm,
    name: "Register",
    layout: RouteLayouts.RegistrationLayout,
  },
  {
    path: "/register",
    component: Register,
    name: "Register",
    layout: RouteLayouts.RegistrationLayout,
  },
  {
    path: "/my-matches/:id",
    component: DetailProfile,
    name: "Matched Profile Details",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/my-matches",
    component: MyMatches,
    name: "My Matches",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/my-notification",
    component: Notification,
    name: "My Notification",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/my-conversations",
    component: Conversations,
    name: "My Conversations",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/my-profile/delete",
    component: ProfileDeletion,
    name: "User Profile Deletion",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/my-profile/edit",
    component: ProfileEdit,
    name: "Edit Profile",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/my-profile",
    component: UserProfile,
    name: "My Profile",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/my-account",
    component: MyAccount,
    name: "My Account",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/my-inbox",
    component: MyInbox,
    name: "My Inbox",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/donate",
    component: Donate,
    name: "Donate",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
    name: "Terms And Conditions",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/faq",
    component: FAQ,
    name: "Frequently Asked Questions",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/activity-dashboard",
    component: ActivityDashboard,
    name: "Activity Dashboard",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/b-activity-dashboard",
    component: ActivityDashboard,
    name: "Activity Dashboard",
    layout: RouteLayouts.BlankLayout,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    name: "Privacy Policy",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/cancellation-policy",
    component: CancellationPolicy,
    name: "Cancellation Policy",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/vvs-prime",
    component: VVSPrime,
    name: "VVS PRIME Benefits",
    layout: RouteLayouts.HomeLayout,
  },
  {
    path: "/b-donate",
    component: Donate,
    name: "Donate",
    layout: RouteLayouts.BlankLayout,
  },
  {
    path: "/b-terms-and-conditions",
    component: TermsAndConditions,
    name: "Terms And Conditions",
    layout: RouteLayouts.BlankLayout,
  },
  {
    path: "/b-faq",
    component: FAQ,
    name: "Frequently Asked Questions",
    layout: RouteLayouts.BlankLayout,
  },
  {
    path: "/b-privacy-policy",
    component: PrivacyPolicy,
    name: "Privacy Policy",
    layout: RouteLayouts.BlankLayout,
  },
  {
    path: "/b-cancellation-policy",
    component: CancellationPolicy,
    name: "Cancellation Policy",
    layout: RouteLayouts.BlankLayout,
  },
  {
    path: "/b-vvs-prime",
    component: VVSPrime,
    name: "VVS PRIME Benefits",
    layout: RouteLayouts.BlankLayout,
  },
  {
    path: "/",
    component: Home,
    name: "Home",
    layout: RouteLayouts.HomeLayout,
  },
];

export default routes;
